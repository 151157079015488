import type { Company } from 'api/types/companies'
import type { UserSettings, UserSettingsResponse } from 'api/types/user-settings'
import type { UserId, RegisteredUser } from 'api/types/users'
import { formatName } from './helpers'

export const getCompanyUser = (
  userId: UserId | undefined,
  company: Company | undefined
) => company?.registeredUsers.find((user) => user.id === userId)

export const getRequiredUserSettings = (
  response: UserSettingsResponse
): UserSettings => ({
  userOnboarded: false,
  bookkeepingDocumentsTableColumns: null,
  capturePromoDialog: 'VISIBLE',
  hubCompaniesSorting: [],
  bankStatementConverterPromoBanner: 'VISIBLE',
  remittanceAdviceNotice: 'VISIBLE',
  ...response,
  preferredView: { ...response.preferredView },
  perCompany: { ...response.perCompany },
})

export const sortByName = (users: RegisteredUser[], locale: string) =>
  users.slice(0).sort((left, right) => {
    const leftName = formatName(left.firstName, left.lastName)
    const rightName = formatName(right.firstName, right.lastName)

    if (leftName === '' || rightName === '') {
      return left.email.localeCompare(right.email)
    }

    return leftName.localeCompare(rightName, locale, { sensitivity: 'base' })
  })

export const sortCurrentUserFirst = (
  users: RegisteredUser[],
  currentUserId: UserId | undefined
) => users.slice(0).sort((a, _b) => (a.id === currentUserId ? -1 : 0))
