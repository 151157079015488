import type { Company } from 'api/types/companies'
import { useTrackUIView } from 'domains/analytics/hooks'
import { FormattedMessage } from 'react-intl'
import { Surface, SurfaceHeader, Button, Point } from 'salad/components'
import * as Icons from 'salad/icons'
import { VStack, HStack, Text } from 'salad/primitives'
import { checkHasPaymentsFreeTrialAddon } from '../../utils'

interface PaymentsFreeTrialOverviewProps {
  company: Company
  onConfirm: () => void
  confirming: boolean
}

export function PaymentsFreeTrialOverview({
  company,
  onConfirm,
  confirming,
}: PaymentsFreeTrialOverviewProps) {
  const hasPaymentsFreeTrialAddon = checkHasPaymentsFreeTrialAddon(company.subscription)

  useTrackUIView({
    data: { target: 'payments-free-trial-flow', step: 'overview' },
    companyId: company.id,
  })

  return (
    <Surface style={{ maxWidth: 512, marginInline: 'auto' }}>
      <VStack p={32} gap={32}>
        <SurfaceHeader.Root>
          <SurfaceHeader.Title>
            <FormattedMessage
              id="billing.payments-free-trial-flow.overview.title"
              defaultMessage="Unlimited payments for one month"
            />
          </SurfaceHeader.Title>
          <SurfaceHeader.Description>
            <FormattedMessage
              id="billing.payments-free-trial-flow.overview.description"
              defaultMessage="Streamline your payments with Apron. Pay bills, reimburse employees, run payroll, pay globally — all in one place."
            />
          </SurfaceHeader.Description>
        </SurfaceHeader.Root>
        <VStack as="ul" gap={24}>
          <HStack as="li" gap={8}>
            <Point>
              <Icons.S16.Check />
            </Point>
            <VStack gap={2}>
              <Text as="h4" variant="title-16" color="surface-primary-text">
                <FormattedMessage
                  id="billing.payments-free-trial-flow.overview.batch-payments.title"
                  defaultMessage="Batch payments"
                />
              </Text>
              <Text as="p" variant="paragraph-16" color="surface-primary-text">
                <FormattedMessage
                  id="billing.payments-free-trial-flow.overview.batch-payments.description"
                  defaultMessage="Pay one, a dozen, or hundreds of bills in one go."
                />
              </Text>
            </VStack>
          </HStack>
          <HStack as="li" gap={8}>
            <Point>
              <Icons.S16.Check />
            </Point>
            <VStack gap={2}>
              <Text as="h4" variant="title-16" color="surface-primary-text">
                <FormattedMessage
                  id="billing.payments-free-trial-flow.overview.reconciliation.title"
                  defaultMessage="Automatic reconciliation"
                />
              </Text>
              <Text as="p" variant="paragraph-16" color="surface-primary-text">
                <FormattedMessage
                  id="billing.payments-free-trial-flow.overview.reconciliation.description"
                  defaultMessage="Payments are reconciled instantly in your accounting app."
                />
              </Text>
            </VStack>
          </HStack>
          <HStack as="li" gap={8}>
            <Point>
              <Icons.S16.Check />
            </Point>
            <VStack gap={2}>
              <Text as="h4" variant="title-16" color="surface-primary-text">
                <FormattedMessage
                  id="billing.payments-free-trial-flow.overview.workflows.title"
                  defaultMessage="Approval workflows"
                />
              </Text>
              <Text as="p" variant="paragraph-16" color="surface-primary-text">
                <FormattedMessage
                  id="billing.payments-free-trial-flow.overview.workflows.description"
                  defaultMessage="Customise who can prepare, approve, and complete a payment."
                />
              </Text>
            </VStack>
          </HStack>
          <HStack as="li" gap={8}>
            <Point>
              <Icons.S16.Check />
            </Point>
            <VStack gap={2}>
              <Text as="h4" variant="title-16" color="surface-primary-text">
                <FormattedMessage
                  id="billing.payments-free-trial-flow.overview.international-payments.title"
                  defaultMessage="Pay internationally"
                />
              </Text>
              <Text as="p" variant="paragraph-16" color="surface-primary-text">
                <FormattedMessage
                  id="billing.payments-free-trial-flow.overview.international-payments.description"
                  defaultMessage="Send money to over 150 countries at mid-market FX rates."
                />
              </Text>
            </VStack>
          </HStack>
        </VStack>
        <Button.Root
          variant="main"
          size="large"
          onClick={() => onConfirm()}
          loading={confirming}
          disabled={hasPaymentsFreeTrialAddon}
        >
          <Button.Content>
            {hasPaymentsFreeTrialAddon ? (
              <FormattedMessage
                id="billing.payments-free-trial-flow.trial-started"
                defaultMessage="Trial started"
              />
            ) : (
              <FormattedMessage
                id="billing.payments-free-trial-flow.start-trial"
                defaultMessage="Start free trial"
              />
            )}
          </Button.Content>
        </Button.Root>
      </VStack>
    </Surface>
  )
}
