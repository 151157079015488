import { init as initFullStory, FullStory } from '@fullstory/browser'
import { createContext, useState, useEffect } from 'react'

const START_CAPTURE_PROBABILITY = 0.5

export const FullstoryContext = createContext({
  isFullstoryInitialized: false,
})

export const FullstoryProvider = ({ children }: { children?: React.ReactNode }) => {
  const [isFullstoryInitialized, setIsFullstoryInitialized] = useState(false)

  useEffect(() => {
    if (
      process.env.BUILD_MODE === 'production' && // only enable in production
      process.env.BRANCH_STAGING === undefined && // disable on PR preview stands
      process.env.PLAYWRIGHT === undefined // disable for test runs
    ) {
      initFullStory({
        orgId: process.env.FULLSTORY_ORG_ID,
        host: 'app.getapron.com/fsrelay',
        script: 'cdn.getapron.com/scripts/fs.js',
        startCaptureManually: true,
      })

      FullStory('observe', {
        type: 'start',
        callback: () => {
          setIsFullstoryInitialized(true)
          if (Math.random() < START_CAPTURE_PROBABILITY) {
            FullStory('start')
          }
        },
      })
    }
  }, [])

  return (
    <FullstoryContext.Provider value={{ isFullstoryInitialized }}>
      {children}
    </FullstoryContext.Provider>
  )
}
