import type { User } from 'api/types/users'
import type { PaymentItem, NextToAct } from 'domains/payment/types'
import { ErrorCode } from '../types'
import { CardPaymentError, ResponseError, ExtendedResponseError } from './error'
import { formatName } from './helpers'

export function isPaymentsSuspendedError(error: unknown) {
  return error instanceof ResponseError && error.response.status === 503
}

export function isCardPaymentFailedError(error: unknown) {
  return error instanceof CardPaymentError && error.meta?.state === 'DECLINED'
}

export function isInsufficientFundsError(error: unknown) {
  return (
    error instanceof ExtendedResponseError &&
    error.meta?.errorCode === ErrorCode.INSUFFICIENT_FUNDS
  )
}

export function isInsufficientRolePermissionsError(error: unknown) {
  return (
    error instanceof ExtendedResponseError &&
    error.meta?.errorCode === ErrorCode.INSUFFICIENT_ROLE_PERMISSIONS_FOR_ALL_COMPANIES
  )
}

export function isPermissionsRequestAlreadyExistError(error: unknown) {
  return (
    error instanceof ExtendedResponseError &&
    error.meta?.errorCode === ErrorCode.PERMISSIONS_REQUEST_ALREADY_EXIST
  )
}

export function isConnectionLost(error: unknown) {
  return error instanceof ResponseError && error.response.status === 401
}

export function isPayrunLine(paymentItem: PaymentItem) {
  return 'invoiceId' in paymentItem
}

export function isInvoice(paymentItem: PaymentItem) {
  return 'invoiceNumber' in paymentItem // the rest have "reference"
}

export const isCurrentUserTurn = (nextToAct: NextToAct | null, currentUser?: User) => {
  if (nextToAct === null || currentUser === undefined) {
    return false
  }
  return nextToAct.contributors.some(({ id }) => id === currentUser.id)
}

export const getActorName = (nextToAct: NextToAct, currentUser?: User) => {
  if (nextToAct.contributors.length > 1) {
    return `Any of ${nextToAct.contributors.length} ${nextToAct.actionRole}s’`
  }
  const contributor = nextToAct.contributors[0]
  const fullName = formatName(contributor.firstName, contributor.lastName)
  return isCurrentUserTurn(nextToAct, currentUser) ? 'your' : `${fullName}’s`
}

export function getTurnDescription(nextToAct: NextToAct | null, currentUser?: User) {
  if (nextToAct === null) {
    return 'Awaiting authorisation'
  }

  const action = nextToAct.actionRole === 'payer' ? 'pay' : 'approve'
  const name = getActorName(nextToAct, currentUser)

  return `It’s ${name} turn to ${action}.`
}
