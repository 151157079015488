import {
  usePractice,
  useCompanySettings,
  useUpdateCompanySettings,
} from 'api/hooks/companies'
import type { Company } from 'api/types/companies'
import { useSendInteraction } from 'domains/analytics/hooks'
import {
  PaymentsFreeTrialBanner,
  PaymentsFreeTrialFlow,
  PaymentsFreeTrialBannerProgress,
} from 'domains/billing/components'
import {
  getPaymentsFreeTrialSubscriptionAddon,
  getSubscriptionAddonDays,
  checkIsCompanyEligibleForPaymentsFreeTrial,
} from 'domains/billing/utils'
import { FormattedMessage } from 'domains/i18n/components'
import { usePaymentStats } from 'domains/payment/queries'
import { useQueryState, parseAsStringLiteral } from 'kitchen/router'
import { useState } from 'react'
import { Dialog, AlertDialog } from 'salad/components'
import * as Icons from 'salad/icons'
import { VStack } from 'salad/primitives'
import { match, P } from 'ts-pattern'

interface AppearProps {
  state: 'visible' | 'hidden'
  children?: React.ReactNode
}

function Appear({ state, children }: AppearProps) {
  return (
    <VStack
      style={{
        transitionProperty: 'opacity, transform',
        transitionDuration: '300ms',
        transitionDelay: '800ms',
        opacity: state === 'visible' ? 1 : 0,
        transform: `translateY(${state === 'visible' ? 0 : 16}px)`,
      }}
    >
      {children}
    </VStack>
  )
}

interface AppSidebarPaymentsFreeTrialProps {
  company: Company
}

export function AppSidebarPaymentsFreeTrial({
  company,
}: AppSidebarPaymentsFreeTrialProps) {
  const practice = usePractice()
  const paymentStats = usePaymentStats({ companyId: company.id })
  const companySettings = useCompanySettings({ companyId: company.id })
  const updateCompanySettings = useUpdateCompanySettings()
  const sendInteraction = useSendInteraction(company.id)

  const paymentsFreeTrialAddon =
    company.subscription === null
      ? undefined
      : getPaymentsFreeTrialSubscriptionAddon(company.subscription)

  const hasPaymentsFreeTrial = paymentsFreeTrialAddon !== undefined

  const [xmasPaymentFreeTrialOfferState, setXmasPaymentFreeTrialOfferState] =
    useQueryState(
      'xmas-payment-free-trial-offer',
      parseAsStringLiteral(['open', 'closed']).withDefault('closed')
    )

  const [open, setOpen] = useState<boolean>(
    !hasPaymentsFreeTrial && xmasPaymentFreeTrialOfferState === 'open'
  )

  if (
    paymentStats.isError ||
    paymentStats.isLoading ||
    practice.isError ||
    practice.isLoading ||
    companySettings.isLoading ||
    companySettings.isError ||
    !(
      checkIsCompanyEligibleForPaymentsFreeTrial(
        company.subscription,
        practice.data,
        paymentStats.data
      ) || hasPaymentsFreeTrial
    )
  ) {
    return <Appear state="hidden" />
  }

  const handleClose = () => {
    setOpen(false)
    setXmasPaymentFreeTrialOfferState('closed')
  }

  return (
    <Appear state="visible">
      {match({
        addon: paymentsFreeTrialAddon,
        banner: companySettings.data.paymentsFreeTrialBanner,
      })
        .with({ addon: P.nonNullable, banner: 'VISIBLE' }, (paymentsFreeTrial) => (
          <AlertDialog.Root>
            <AlertDialog.Trigger>
              <PaymentsFreeTrialBannerProgress addon={paymentsFreeTrial.addon} />
            </AlertDialog.Trigger>
            {match(paymentsFreeTrial.addon.state)
              .with('ACTIVE', () => (
                <AlertDialog.Content>
                  <AlertDialog.Title>
                    <FormattedMessage
                      id="billing.payments-free-trial-active.title"
                      defaultMessage="Unlimited payments trial"
                    />
                  </AlertDialog.Title>
                  <AlertDialog.Description>
                    <FormattedMessage
                      id="billing.payments-free-trial-active.description"
                      defaultMessage="Your company can make unlimited payments for {days} days. Once your trial ends continue making payments on your current plan or upgrade."
                      values={{ days: getSubscriptionAddonDays(paymentsFreeTrial.addon) }}
                    />
                  </AlertDialog.Description>
                  <AlertDialog.Cancel variant="common">
                    <FormattedMessage id="common.acknowledge" defaultMessage="Got it" />
                  </AlertDialog.Cancel>
                </AlertDialog.Content>
              ))
              .with('ENDED', () => (
                <AlertDialog.Content>
                  <AlertDialog.Icon>
                    <Icons.S64.Alert />
                  </AlertDialog.Icon>
                  <AlertDialog.Title>
                    <FormattedMessage
                      id="billing.payments-free-trial-expired.title"
                      defaultMessage="Your free payments trial has ended"
                    />
                  </AlertDialog.Title>
                  <AlertDialog.Description>
                    <FormattedMessage
                      id="billing.payments-free-trial-expired.description"
                      defaultMessage="You can continue making payments on your current plan or upgrade."
                    />
                  </AlertDialog.Description>
                  <AlertDialog.Cancel
                    variant="common"
                    onClick={() =>
                      updateCompanySettings.mutate({
                        companyId: company.id,
                        paymentsFreeTrialBanner: 'HIDDEN',
                      })
                    }
                  >
                    <FormattedMessage id="common.acknowledge" defaultMessage="Got it" />
                  </AlertDialog.Cancel>
                </AlertDialog.Content>
              ))
              .exhaustive()}
          </AlertDialog.Root>
        ))
        .with({ addon: undefined }, () => (
          <PaymentsFreeTrialBanner
            onStart={() => {
              setOpen(true)
              sendInteraction({
                type: 'click',
                target: 'view-payments-free-trial',
              })
            }}
          />
        ))
        .with({ banner: 'HIDDEN' }, () => null)
        .exhaustive()}
      <Dialog.Root open={open} onOpenChange={handleClose}>
        <Dialog.Content variant={848}>
          <PaymentsFreeTrialFlow
            initialStep="overview"
            company={company}
            paymentStats={paymentStats.data}
            onExit={handleClose}
          />
        </Dialog.Content>
      </Dialog.Root>
    </Appear>
  )
}
