export const Bank = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.99902L20.8948 6.44861C21.5722 6.78746 22 7.47988 22 8.23729V11H2V8.23729C2 7.47988 2.42785 6.78746 3.10522 6.44861L12 1.99902ZM12 4.23531L4 8.23729L4 8.99998H20V8.23729L12 4.23531ZM7 12H5V19H7V12ZM2 20V22H22V20H2ZM13 12V19H11V12H13ZM19 12H17V19H19V12ZM12 7.99997C12.5523 7.99997 13 7.55225 13 6.99997C13 6.44768 12.5523 5.99997 12 5.99997C11.4477 5.99997 11 6.44768 11 6.99997C11 7.55225 11.4477 7.99997 12 7.99997Z"
      fill="currentColor"
    />
  </svg>
)
