import { useCurrentUserHasPermission } from 'api/hooks/permissions'
import type { Company } from 'api/types/companies'
import { useCompanySubscriptions } from 'domains/billing/queries'
import { getSubscriptionState } from 'domains/billing/utils'
import { FormattedMessage } from 'domains/i18n/components'
import { useNavigate, generatePath } from 'kitchen/router'
import { Chip } from 'salad/components'
import { ButtonBase } from 'salad/primitives'
import { match, P } from 'ts-pattern'
import { Path } from '@app/constants'

interface TrialStatusChipProps {
  company: Company
}

export function TrialStatusChip({ company }: TrialStatusChipProps) {
  const navigate = useNavigate()
  const subscriptions = useCompanySubscriptions({ subscriberId: company.id })

  const [canViewSubscriptions] = useCurrentUserHasPermission(company.id, [
    'SUBSCRIPTIONS_VIEW',
  ])

  if (subscriptions.isLoading || subscriptions.isError) {
    return null
  }

  return match(getSubscriptionState(company.id, subscriptions.data))
    .with(
      {
        type: 'expiring',
        subscription: { pricingPlan: { name: 'Trial' } },
      },
      (state) => {
        // Handled in SubscriptionStatusBanner
        if (state.daysLeft <= 7) {
          return null
        }

        return (
          <ButtonBase
            onClick={() =>
              navigate(
                generatePath(Path.COMPANY_SUBSCRIPTION_PLAN, { companyId: company.id })
              )
            }
            css={{ width: 'auto', display: 'none', '@bp2': { display: 'block' } }}
            disabled={!canViewSubscriptions}
          >
            <Chip variant="neutral">
              <FormattedMessage
                id="app.trial-status"
                defaultMessage={`{days, plural,
                  one {# day free trial}
                  other {# days free trial}
                }`}
                values={{ days: state.daysLeft }}
              />
            </Chip>
          </ButtonBase>
        )
      }
    )
    .with(
      {
        type: P.union(
          'none',
          'expiring',
          'expired',
          'active',
          'switching-payers',
          'switching-plan'
        ),
      },
      () => null
    )
    .exhaustive()
}
