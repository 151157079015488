import type { CountryCode } from 'api/types/address'
import { getIntl } from 'domains/i18n/utils'
import { alphabeticSort, groupBy } from 'kitchen/utils/data'
import { matchSorter } from 'match-sorter'
import { Fragment, useState } from 'react'
import { SearchInput, Select, Item, Label, Popover, ScrollArea } from 'salad/components'
import { HStack, Image, Text, VStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import type { PhoneCountryCallingCode, PhoneCountryCallingCodeId } from '../types'
import { formatCountryCallingCode } from '../utils'

interface Option {
  value: PhoneCountryCallingCodeId
  label: string
  countryCode: CountryCode
  countryName: string
  countryImage: string
}

interface CountryCallingCodeOptionProps {
  option: Option
}

function CountryCallingCodeOption({ option }: CountryCallingCodeOptionProps) {
  return (
    <Select.Option value={option.value}>
      <Item.Root>
        <Item.Start>
          <Image src={option.countryImage} width={24} height={24} alt="" />
        </Item.Start>
        <Item.Content css={{ paddingInlineStart: theme.space[4] }}>
          <HStack gap={8}>
            <Text variant="paragraph-14" color="grey-40">
              {option.label}
            </Text>
            <Text variant="title-14">{option.countryName}</Text>
          </HStack>
        </Item.Content>
      </Item.Root>
    </Select.Option>
  )
}

interface CountryCallingCodeOptionGroupsProps {
  options: Option[]
}

function CountryCallingCodeOptionGroups({
  options,
}: CountryCallingCodeOptionGroupsProps) {
  const { locale } = getIntl()
  const groups = groupBy(options, (option) => option.countryName.charAt(0), locale)

  return (
    <>
      {Object.keys(groups)
        .sort()
        .map((groupName) => {
          return (
            <Fragment key={groupName}>
              <VStack px={12}>
                <Label size={13}>{groupName}</Label>
              </VStack>
              <VStack gap={2}>
                {groups[groupName]
                  .slice()
                  .sort((a, b) => alphabeticSort(a.countryName, b.countryName, locale))
                  .map((option) => (
                    <CountryCallingCodeOption key={option.value} option={option} />
                  ))}
              </VStack>
            </Fragment>
          )
        })}
    </>
  )
}

interface PhoneCountryCallingCodeSelectProps {
  options: PhoneCountryCallingCode[]
  suggested?: CountryCode[]
  value: PhoneCountryCallingCodeId | null
  onSelect: (value: PhoneCountryCallingCodeId | null) => void
}

export function PhoneCountryCallingCodeSelect({
  options,
  suggested: suggestedSource = [],
  value,
  onSelect,
}: PhoneCountryCallingCodeSelectProps) {
  const [search, setSearch] = useState<string>('')

  const formatted: Option[] = options.map(
    ({ id, callingCode, countryCode, countryName, countryImage }) => {
      return {
        value: id,
        label: formatCountryCallingCode(callingCode),
        countryCode,
        countryName: countryName || '',
        countryImage,
      }
    }
  )

  const suggested = formatted.filter(({ countryCode }) =>
    suggestedSource?.includes(countryCode)
  )
  const filtered = matchSorter(formatted, search, {
    keys: ['label', 'value', 'countryName'],
  })

  return (
    <VStack gap={12} p={16}>
      <VStack px={8}>
        <SearchInput
          placeholder="Search country..."
          value={search}
          onValueChange={setSearch}
        />
      </VStack>
      <ScrollArea
        variant="custom-scrollbar"
        css={{
          maxHeight: `min(400px, calc(${Popover.availableContentHeight} - ${theme.space[16]}))`,
        }}
      >
        <Select.Root value={value} onValueChange={onSelect}>
          <VStack gap={8} p={8}>
            {search !== '' ? (
              <VStack gap={2}>
                {filtered.map((option) => {
                  return <CountryCallingCodeOption key={option.value} option={option} />
                })}
              </VStack>
            ) : (
              <>
                <VStack gap={2}>
                  {suggested.map((option) => {
                    return <CountryCallingCodeOption key={option.value} option={option} />
                  })}
                </VStack>
                <CountryCallingCodeOptionGroups options={formatted} />
              </>
            )}
          </VStack>
        </Select.Root>
      </ScrollArea>
    </VStack>
  )
}
