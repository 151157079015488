import { usePractice } from 'api/hooks/companies'
import { useHasFeatureFlag } from 'api/hooks/features'
import type { User } from 'api/types/users'
import { ActiveRouterLink } from 'kitchen/router'
import { getCompanyUserPermissions } from 'kitchen/utils/permissions'
import { FormattedMessage } from 'react-intl'
import { Item, SidebarNav } from 'salad/components'
import * as Icons from 'salad/icons'
import { VStack } from 'salad/primitives'
import { Path } from '@app/constants'

interface AppSidebarHubNavItemsProps {
  user: User
}

export function AppSidebarHubNavItems({ user }: AppSidebarHubNavItemsProps) {
  const [hasBankStatementsConverter] = useHasFeatureFlag(['BANK_STATEMENT_CONVERTER'])

  const practice = usePractice().data
  const hasPractice = practice !== undefined
  const permissions = hasPractice
    ? getCompanyUserPermissions(user.id, practice)
    : undefined

  return (
    <VStack gap={24}>
      <VStack>
        <SidebarNav.Label>{hasPractice ? 'Practice Hub' : 'Hub'}</SidebarNav.Label>
        <SidebarNav.Item
          as={ActiveRouterLink}
          to={Path.COMPANIES_HUB}
          match={[{ path: Path.COMPANIES_HUB }]}
        >
          <Item.Start>
            <Icons.S24.Dashboard />
          </Item.Start>
          <Item.Content>{hasPractice ? 'Clients' : 'Companies'}</Item.Content>
        </SidebarNav.Item>
        {permissions !== undefined && (
          <>
            <SidebarNav.Item as={ActiveRouterLink} to={Path.PRACTICE_TEAM}>
              <Item.Start>
                <Icons.S24.Members />
              </Item.Start>
              <Item.Content>Team</Item.Content>
            </SidebarNav.Item>
            {permissions.includes('SUBSCRIPTIONS_VIEW') && (
              <SidebarNav.Item
                as={ActiveRouterLink}
                to={Path.PRACTICE_SUBSCRIPTION_PLANS}
                match={[{ path: Path.PRACTICE_SUBSCRIPTION, end: false }]}
              >
                <Item.Start>
                  <Icons.S24.Manual />
                </Item.Start>
                <Item.Content>Billing</Item.Content>
              </SidebarNav.Item>
            )}
          </>
        )}
      </VStack>
      {hasBankStatementsConverter && hasPractice ? (
        <VStack>
          <SidebarNav.Label>
            <FormattedMessage id="app.sidebar.label.tools" defaultMessage="Tools" />
          </SidebarNav.Label>
          <SidebarNav.Item
            as={ActiveRouterLink}
            to={Path.COMPANIES_HUB_BANK_STATEMENT_CONVERTER}
            match={[
              { path: Path.COMPANIES_HUB_BANK_STATEMENT_CONVERTER },
              { path: Path.COMPANY_BANK_STATEMENTS },
            ]}
          >
            <Item.Start>
              <Icons.S24.Bank />
            </Item.Start>
            <Item.Content>
              <FormattedMessage
                id="app.sidebar.menu-item.converter"
                defaultMessage="Converter"
              />
            </Item.Content>
          </SidebarNav.Item>
        </VStack>
      ) : null}
    </VStack>
  )
}
